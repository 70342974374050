import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {map} from 'rxjs/operators';

import {AuthResponse} from '../@core/data/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  redirectUrl: string;

  constructor(private afauth: AngularFireAuth) {
  }

  login(email, password): Promise<AuthResponse> {
    return this.afauth.signInWithEmailAndPassword(email, password).then(() => {
      return Promise.resolve({
        result: true,
        message: 'User logged in',
      });
    }, err => {
      return Promise.reject({
        result: false,
        message: err.message,
      });
    });
  }

  register(email, password): Promise<AuthResponse> {
    return this.afauth.createUserWithEmailAndPassword(email, password).then(() => {
      return Promise.resolve({
        result: true,
        message: 'User created successfully',
      });
    }, err => {
      return Promise.reject({
        result: false,
        message: err.message,
      });
    });
  }

  signout(): Promise<AuthResponse> {
    return this.afauth.signOut().then(() => {
      return Promise.resolve({
        result: true,
        message: 'User signed out successfully',
      });
    }, err => {
      return Promise.reject({
        result: false,
        message: err.message,
      });
    });
  }

  isLoggedIn() {
    return this.afauth.authState.pipe(
      // map(user => !!user),
      map(user => true),
    );
  }
}
